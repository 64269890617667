import React from "react"
import License from "./licenses.js";
import Illustration from "../../../../assets/img/global/cta-loanCalculator.inline.svg"
import EntryBadgesV2 from "./badgesV2.js"

const CTALoanEligiblity = props => {
  return (
    <div className="kuda-section">
      <div className="kuda-entry--inner kuda-cta--section flex justify-between align-center">
        <div className="kuda-section--50">
          <div className="kuda-main--wrap">
            <h1 className="kuda-section--heading text-xlbold color-primary title-bottom--spacing">
              Get the app now, dump bank fees for good.
            </h1>
            <p className="color-black bottom--spacing">
              It only takes a few minutes to start enjoying free benefits.
              Download Kuda on Google Play or the App Store.


            </p>

            <EntryBadgesV2 className="bottom--spacing" />
            <License className="bottom--spacing" />
          </div>
        </div>
        <div className="kuda-section--50">
          <div className="cta-illustration">
            <Illustration />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CTALoanEligiblity)
